import { Dialog, styled } from "@mui/material";

export const WideDialog = styled(Dialog)(({ theme }) => ({
  "& form.MuiDialog-paper": {
    minWidth: '800px',
    maxWith: '800px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWith: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      maxWith: 'none'
    },
  }
}));
