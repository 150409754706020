import ErrorIcon from "@mui/icons-material/Error";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  IconButton,
  List,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { useLoaderData, useLocation, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { ApiId, DatasetModel, LabelColumnPrefix, DataStatisticsResult, JobInfoModel, JobStatus, LabelMappings, ProcessorModel, ProjectModel } from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import { StandardCard } from "../../components/common/StandardCard";
import * as PageInfo from "../../pages/PageInfo";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Apis } from "../../api/apis";
import { AllColumns } from "../../api/data";
import { ApiBatchResult } from "../../api/types";
import DataHeaderItem from "../../components/common/DataHeaderItem";
import DatasetVersionPanel from "../../components/dataset/DatasetVersionPanel";
import { explorationPath } from "../../pages/PageInfo";
import { sortedResource } from "../../util/sorting";
import { requiredColumns } from "../explorations/ExplorationsPage";

import LessIcon from "@mui/icons-material/ChevronLeft";
import MoreIcon from "@mui/icons-material/ChevronRight";
import { DateText } from "../../components/common/DateText";
import { EditDetailsDialog } from "../../components/common/EditDetailsDialog";
import { formatJsonDate } from "../../util/dateformat";
import { createDownloadHandler } from "../../util/htmldom";
import { createJobIdMonitor } from "../../util/monitors";
import { DatasetPreviewModal } from "./DatasetPreviewModal";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { VisuallyHiddenInput } from "../../components/common/FileUploadButton";

const standardColumnIds = new Set<string>(AllColumns);

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  opacity: 0.75,
}));

const DescriptionTextSpan = styled('span')(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  opacity: 0.75,
}));


const ColumnChip = styled(Chip)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 0.9,
  marginRight: theme.spacing(0.5),
  marginTop: theme.spacing(0.25),
  marginBottom: theme.spacing(0.25),
  height: theme.spacing(2.5),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  textOverflow: "ellipsis",
  maxWidth: "12em",
}));

type ColumnsListProps = {
  columns: string[];
  title?: string;
  original: Set<string>;
  hideStandard?: boolean;
  expanded?: boolean;
  hideShowAll?: boolean;
  sx?: SxProps<Theme>;
};

function ColumnsList(props: ColumnsListProps) {
  const { columns, title, original, sx, expanded, hideStandard, hideShowAll } = props; 
  const [showAll, setShowAll] = useState(!!expanded);
  const theme = useTheme();
  let nothingToExpand = false;
  let allAdded = new Set(columns).difference(original);

  if (!showAll) {
    const standardAdded = allAdded.intersection(standardColumnIds);
    nothingToExpand = standardAdded.size === allAdded.size;
    allAdded = standardAdded;
  }
  if (!!hideStandard) {
    allAdded = allAdded.difference(standardColumnIds);
  }
  const added = Array.from(allAdded);

  const onClickShowAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAll(!showAll);
  };

  return (
    <Box sx={sx}>
      {title && (<Typography sx={{display: 'inline-flex', fontWeight: 600, mr: 1}}>{title}</Typography>)}
      {added.map((t: string) => (
        <ColumnChip
          key={t}
          label={t}
          size="small"
          sx={{ mr: 0.6, lineHeight: theme.typography.caption.fontSize }}
          title={standardColumnIds.has(t) ? "Standard column" : "Custom column"}
          color={standardColumnIds.has(t) ? "primary" : "default"}
        />
      ))}
      {!hideShowAll && (
        <IconButton size="small" sx={{ p: 0 }} color="primary" onClick={onClickShowAll} disabled={nothingToExpand}>
          {showAll ? <LessIcon /> : <MoreIcon />}
        </IconButton>
      )}
    </Box>
  );
};

type OriginalDatasetInfoProps = {
  projectId: number;
  dataset: DatasetModel;
  labels: LabelMappings | undefined;
  onDelete: () => void;
  onSelectReferenceId: (id: number) => void;
  sx?: SxProps<Theme>;
};

function validateDatasetLabels(dataset: DatasetModel, labelDef: Record<string, object>) {
  const columnRe = new RegExp(`^${LabelColumnPrefix}`, "")
  const datasetColumnSet = new Set(dataset.columns?.map((c) => c.replace(columnRe, "")));
  const labelsSet = new Set(Object.keys(labelDef));
  const diff = labelsSet.difference(datasetColumnSet);
  return diff.size === 0;
}

type DownloadMenuProps = {
  projectId: ApiId;
  dataset: DatasetModel;
};

function DownloadMenu(props: DownloadMenuProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { projectId, dataset} = props;
  const [ open, setOpen ] = useState(false);
  const downloadUrl = Apis.shared().project.datasetDownloadUrl(projectId ?? "", dataset.id ?? "");
  const labelsDownloadUrl = dataset.labels_filename ? Apis.shared().project.datasetLabelsDownloadUrl(projectId ?? "", dataset.id ?? ""): undefined;
  const onDownload = createDownloadHandler(dataset.filename!, downloadUrl, (url) => Apis.shared().project.signedLink(url));
  const onDownloadLabels = labelsDownloadUrl ? createDownloadHandler(dataset.labels_filename!, labelsDownloadUrl, (url) => Apis.shared().project.signedLink(url)) : undefined;
  return !!labelsDownloadUrl ? (<>
    <Button ref={anchorRef} onClick={() => { setOpen((prevOpen) => !prevOpen)}}>Download</Button>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}>
          <Paper>
            <ClickAwayListener onClickAway={()=> { setOpen(false); }}>
              <MenuList>
                <MenuItem onClick={(e) => { setOpen(false); onDownload(e); }}>Dataset</MenuItem>
                <MenuItem  onClick={(e) => { setOpen(false); onDownloadLabels!(e); }}>Labels</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>) : (
    <Button onClick={onDownload}>Download</Button>
  );
}

function OriginalDatasetInfo(props: OriginalDatasetInfoProps) {
  const { dataset, labels, projectId, onDelete, onSelectReferenceId, sx } = props;
  const columns = (dataset.columns ?? []).map((c) => (c.length > 0 ? c : "[EMPTY]"));
  const [statistics, setStatistics] = useState<DataStatisticsResult | undefined>();
  const [statisticsError, setStatisticsError] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [ showPreview, setShowPreview ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ labelsError, setLabelsError ] = useState(false);
  const location = useLocation();
  const api = Apis.shared().project;
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!statistics) {
      Apis.shared()
        .data.queryStatistics(projectId, dataset.id!, dataset.filename!)
        .then(setStatistics)
        .catch((error) => {
          setStatisticsError(true);
          console.error(error);
        });
    }
  }, [dataset.filename, dataset.id, projectId, statistics]);

  const onEditDialogClose = () => {
    setEditOpen(false);
  };

  const onEditDialogSave = (name: string, description: string) => {
    Apis.shared()
      .project.updateDataset(projectId, dataset.id!, name, description)
      .then((result) => {
        navigate(".", { replace: true });
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onEditDialogDelete = () => {
    Apis.shared().project.deleteDataset(projectId, dataset.id!)
      .then(() => {
        if (!dataset.root_id) {
          navigate("/" + PageInfo.datasetPath(projectId))
        } else {
          navigate(".", { replace: true });
        }
        
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
    setEditOpen(false);
  };

  const onUploadLabels = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    async function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
      const { target } = e;
      setLoading(true);
      const fileReader = new FileReader();
      if ((target.files?.length ?? 0) > 0) {
        const file = target.files![0];
        fileReader.onload = async (e) => {
          const result = e.target?.result;
          if (result) {
            const resourceData = result as ArrayBuffer;
            const decoder = new TextDecoder();
            try {
              const labelJson = JSON.parse(decoder.decode(resourceData));
              if (!validateDatasetLabels(dataset, labelJson)) {
                throw new Error("Label validation error");
              } else {
                setLabelsError(false);
                await api.uploadDatasetLabelsContent(projectId, dataset.id!, file.name, resourceData);
                navigate(location, {replace: true});
              }
            } catch (e) {
              setLabelsError(true);
            }
          }
          setLoading(false);
        };
        fileReader.readAsArrayBuffer(file);
      }
    }
    onUpload(e);
    return false;
  }, [api, dataset, location, navigate, projectId]);
 
  return (
    <StandardCard sx={sx}>
      <DataHeaderItem
        name={dataset.name}
        description={dataset.description}
        filename={dataset.filename}
        createdAt={dataset.created_at}
        onDelete={onDelete}
      />
      <ColumnsList title="Columns" columns={columns} original={new Set()} sx={{ mt: 2.3 }} />
      {(statistics || statisticsError) && (
        <Stack direction="column">
          {labels && (
          <Typography sx={{ mt: 2 }}>
            <strong>Labels</strong>&nbsp;&nbsp;<DescriptionTextSpan>{Object.keys(labels).map((k) => labels[k]).toSorted((a, b) => a.order - b.order).map((l) => l.name).join(", ")}</DescriptionTextSpan>
          </Typography>
          )}
          {statistics ? (
          <Typography variant="body1" sx={{ mt: 2 }}>
            {statistics.total_rows} rows
            {statistics.original_texts && `, ${statistics.original_texts} original texts`}
            {statistics.topics && `, ${statistics.topics.length} topics`}
          </Typography>
          ) : (
            <Typography color="error" sx={{mt: 2}}>Statistics calcluation failed</Typography>
          )}
          {labelsError && (
            <Typography color="error">Labels do not match the dataset columns or corrupt file</Typography>
          )}
          <Stack direction="row" justifyContent="flex-end">
            <Button onClick={() => (setShowPreview(true))}>Preview</Button>
            <DownloadMenu projectId={projectId} dataset={dataset} />
            <Button component="label">Upload labels
              {!loading && (<VisuallyHiddenInput type="file" onChange={onUploadLabels} />)}
            </Button>
            <Button onClick={() => { setEditOpen(true); }}>
              Edit
            </Button>
            <Button onClick={() => { onSelectReferenceId(dataset.id!); }}>
              New version
            </Button>
          </Stack>
        </Stack>
      )}
      <EditDetailsDialog
        dialogTitle="Edit dataset"
        name={dataset.name}
        description={dataset.description}
        onClose={onEditDialogClose}
        onSave={onEditDialogSave}
        onDelete={onEditDialogDelete}
        open={editOpen}
      />
      {showPreview && (
        <DatasetPreviewModal open={showPreview} projectId={projectId} dataset={dataset} nRows={20} onClose={ () => (setShowPreview(false))} />
      )}
    </StandardCard>
  );
};

const ThemesTable = styled(Table)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: theme.spacing(0.25),
    paddingRight: theme.spacing(1.0),
    border: "none",
  },
  "& .MuiTableCell-root:first-of-type": {
    minWidth: 0,
    width: 0,
    paddingLeft: 0,
    textAlign: "right",
  },
}));

type DatasetVersionItemProps = {
  projectId: number;
  dataset: DatasetModel;
  originalColumns: Set<string>;
  sx?: SxProps<Theme>;
  expanded: boolean;
  jobStatus?: JobStatus;
  processor: ProcessorModel | undefined;
  setExpanded: (id: ApiId, value: boolean) => void; 
  onSelectReferenceId: (id: number) => void;
  onDeleteDataset: (id: ApiId) => void;
};

function datasetAge(dataset: DatasetModel): number {
  return (Date.now() - (new Date(dataset.created_at!)).getTime()) / 1000;
}

// FIXME: Clean up this messy status check. 

function checkIsStuck(dataset: DatasetModel, timeout = 60 * 60) {
  return !dataset.filename && datasetAge(dataset) > timeout;
}

function checkIsPending(status: JobStatus | undefined, dataset: DatasetModel) {
  const hasEndState = !!status && (status.state === 'completed' || status.state === 'error');
  return !(hasEndState || checkIsStuck(dataset));
}

function formatParameters(job: JobInfoModel, processor: ProcessorModel) {
  const params = JSON.parse(job.parameters ?? "{}");
  const defs = processor.manifest?.parameters ?? [];
  return defs.map((d) => {
    return `${d.displayName}: ${params[d.name] ?? d.defaultValue}`
  }).join(", ");
}

const DatasetVersionItem = (props: DatasetVersionItemProps) => {
  const {dataset, sx, originalColumns, projectId, expanded, jobStatus, processor, setExpanded, onSelectReferenceId, onDeleteDataset} = props;
  const { name, description, parent_id, id } = dataset;
  const isPending = checkIsPending(jobStatus, dataset);
  const isError = jobStatus?.state === 'error' || checkIsStuck(dataset);
  const [ showPreview, setShowPreview ] = useState(false);
  const [statistics, setStatistics] = useState<DataStatisticsResult | undefined>();
  const [editOpen, setEditOpen] = useState(false);
  const navigate = useNavigate();
  const requiredColumnsSet = new Set(requiredColumns);
  
  const onToggleAccordion = useCallback(() => {
    setExpanded(id!, !expanded);
    if (!isError) {
      if (!expanded && !statistics) {
        Apis.shared()
          .data.queryStatistics(projectId!, dataset.id!, dataset.filename!)
          .then((result) => {
            setStatistics(result);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [dataset.filename, dataset.id, expanded, id, isError, projectId, setExpanded, statistics]);

  const onEditDialogClose = () => {
    setEditOpen(false);
  };

  const onEditDialogSave = (name: string, description: string) => {
    Apis.shared()
      .project.updateDataset(projectId, dataset.id!, name, description)
      .then((result) => {
        navigate(".", { replace: true });
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onEditDialogDelete = () => {
    onDeleteDataset(dataset.id!);
    setEditOpen(false);
  };

  const onDelete = () => {
    onDeleteDataset(dataset.id!);
  }

  const onExplore = (id: number) => {
    navigate("/" + explorationPath(projectId), { state: { selectedDatasets: [dataset.root_id!, dataset.id!] } });
  };

  const onDownload = createDownloadHandler(
    dataset.filename!, 
    Apis.shared().project.datasetDownloadUrl(projectId ?? "", dataset.id ?? ""), 
    (url) => Apis.shared().project.signedLink(url)
  );
  return (
    <Accordion sx={{...sx, p: 1}} expanded={expanded} onChange={onToggleAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
        {isError && <ErrorIcon color="error" fontSize="small" sx={{ mr: 2 }} />}
        {isPending && (
          (jobStatus?.progress ?? 0) > 0 ? (
            <Tooltip title={`${jobStatus?.state}: ${Math.round(jobStatus!.progress! * 100.0)}`}>
              <CircularProgress variant="determinate" value={Math.round(jobStatus!.progress! * 100.0)} size={18} sx={{mr: 2, mt: "-1px"}} />
            </Tooltip>
          ) : (
            <Tooltip title={jobStatus?.state ?? "Starting"}>
              <CircularProgress size={18} sx={{mr: 2, mt: "-1px"}} />
            </Tooltip>
          )
        )}
        <Box>
          <Typography sx={{ paddingTop: 0.2, whiteSpace: "pre" }}>{!parent_id ? `(Original) ${name}` : name}</Typography>
        </Box>
        <ColumnsList columns={dataset.columns ?? []} original={originalColumns} sx={{ ml: 1 }} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <DescriptionText sx={{ mt: 0, mb: 2 }}>{description}</DescriptionText>
        <DateText sx={{ m: 0, mb: 2 }}>
          <strong>Created:</strong> {formatJsonDate(dataset.created_at!)}&nbsp;
          {dataset.job && processor && (
            <>
            <br /><strong>Model:</strong> <Nav to={"/" + PageInfo.processorPath(projectId, processor.id)}>{processor.name}</Nav>
            <br /><strong>Parameters:</strong> {formatParameters(dataset.job, processor)}
            </>
          )}
          {dataset.parent_id && dataset.parent_id !== dataset.root_id && (
            <span>
              &nbsp;-&nbsp;Created from:&nbsp;
              <Link to={"/" + PageInfo.datasetPath(projectId!, dataset.root_id!) + "?xv=" + dataset.parent_id!} component={RouterLink}>version</Link>
            </span>
          )}
        </DateText>
        {isError && (
          <Box>
            <Typography fontWeight={600}>Model error:</Typography>
            <Typography>{jobStatus?.message}</Typography>
          </Box>
        )}
        {statistics && statistics.themes && (
          <ThemesTable>
            <TableBody>
              {statistics.themes.map(([id, count]) => (
                <TableRow key={id}>
                  <TableCell>{count}</TableCell>
                  <TableCell>
                    <Chip size="small" label={id} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ThemesTable>
        )}
        {statistics && statistics.total_rows && (
          <Box>
            <Typography>Number of rows: {statistics.total_rows}</Typography>
          </Box>
        )}
        {expanded && (
          <EditDetailsDialog
            dialogTitle="Edit version"
            name={dataset.name}
            description={dataset.description}
            onClose={onEditDialogClose}
            onSave={onEditDialogSave}
            onDelete={onEditDialogDelete}
            open={editOpen}
          />
        )}
        { !isPending && !isError && (
        <Stack direction="row" justifyContent="flex-end" sx={{mt: 2}}>
          <Button onClick={() => (setShowPreview(true))}>
            Preview
          </Button>
          <Button onClick={onDownload}>
            Download
          </Button>
          <Button onClick={() => { setEditOpen(true); }}>
            Edit
          </Button>
          {requiredColumnsSet.difference(new Set(dataset.columns)).size === 0 && (
            <Button onClick={() => { onExplore(dataset.id!); }}>
              Explore
            </Button>
          )}
          <Button onClick={() => { onSelectReferenceId(dataset.id!); }}>
            New version
          </Button>
        </Stack>
        )}
        { isError && (
          <Stack direction="row" justifyContent="flex-end" sx={{mt: 2}}>
            <Button onClick={onDelete}>
              Delete
            </Button>
          </Stack>
        )}
      </AccordionDetails>
      {showPreview && (
        <DatasetPreviewModal open={showPreview} projectId={projectId} dataset={dataset} nRows={20} onClose={ () => (setShowPreview(false))} />
      )}
    </Accordion>
  );
};

type DatasetVersionsListProps = {
  projectId: number;
  original: DatasetModel;
  versions: DatasetModel[];
  sx?: SxProps<Theme>;
  statusMap: Record<ApiId, JobStatus | undefined>;
  processors: ProcessorModel[];
  onSelectReferenceId: (id: number) => void;
  onDeleteDataset: (id: ApiId) => void;
};

const DatasetVersionsList = (props: DatasetVersionsListProps) => {
  const {versions, sx, original, projectId, statusMap, processors, onSelectReferenceId, onDeleteDataset} = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const originalColumns = new Set(original.columns ?? []);
  const expandedItems = new Set(searchParams.getAll("xv"));

  const setExpanded = (id: ApiId, value: boolean) => {
    const sId = id + "";
    const newSearchParams = new URLSearchParams(searchParams);
    const newExpandedItems = new Set(expandedItems);
    if (value) {
      newExpandedItems.add(sId);
    } else {
      newExpandedItems.delete(sId);
    }
    newSearchParams.delete('xv');
    newExpandedItems.forEach((i) => newSearchParams.append('xv', i));
    setSearchParams(newSearchParams);
  };

  const processorMap = useMemo(() => {
    const m: Record<ApiId, ProcessorModel> = {};
    processors.forEach((p) => {m[p.id!] = p; });
    return m;
  }, [processors]);

  return (
    <Box sx={sx}>
      <List>
        {versions.map((d) => (
          <DatasetVersionItem
            dataset={d}
            key={d.id}
            originalColumns={originalColumns}
            projectId={projectId}
            expanded={expandedItems.has(d.id + "")}
            processor={d.job?.processor_id ? processorMap[d.job?.processor_id!]: undefined}
            jobStatus={d.job && d.job_id! in statusMap ? statusMap[d.job_id!] : d.job?.status}
            setExpanded={setExpanded}
            onSelectReferenceId={onSelectReferenceId}
            onDeleteDataset={onDeleteDataset}
          />
        ))}
      </List>
    </Box>
  );
};

export function DatasetDetailsPage() {
  const navigate = useNavigate();
  const [loadedDatasets, labelData] = useLoaderData() as [ApiBatchResult<DatasetModel>, LabelMappings | undefined];
  const [ datasets, setDatasets ] = useState<ApiBatchResult<DatasetModel>>({count: 0, items: [], empty: true});
  const [ statusMap, setStatusMap ] = useState<Record<ApiId, JobStatus | undefined>>({});
  const project = useRouteLoaderData("project") as ProjectModel;
  const dataset = loadedDatasets.items.find((d) => !d.parent_id)!;
  const allVersions = sortedResource(datasets.items, true);
  const filteredVersions = allVersions.filter((v) => (!!v.parent_id));
  const [referenceVersionId, setReferenceVersionId] = useState<number | undefined>(undefined);
  const [completedSet, setCompletedSet] = useState(new Set<ApiId>());
  
  const itemMonitor = useMemo(() => 
    createJobIdMonitor(async (ids: ApiId[]) => {
      const jobs = await Apis.shared().project.fetchJobs(project.id!, false, true, 'dataset', undefined, ids);
      const pending = jobs.filter((j) => !j.completed);
      const sMap: Record<ApiId, JobStatus | undefined> = {};
      pending.forEach((j) => {sMap[j.id!] = j.status ?? undefined});
      setStatusMap(sMap);
      return pending;
    }, (completedIds: ApiId[]) => {
      const newSet = new Set(completedIds);
      if (newSet.symmetricDifference(completedSet).size > 0) {
        setCompletedSet(newSet);
        // TODO: Fetch only updated datasets and patch the array instead
        Apis.shared().project.fetchDatasets(project.id!, dataset.id!)
          .then(setDatasets);
      }
    }, 2000), [completedSet, dataset.id, project.id]
  );
  
  const onCreated = useCallback(() => {
    Apis.shared().project.fetchDatasets(project.id!, dataset.id!).then(setDatasets);
  }, [dataset.id, project.id]);

  const onDelete = async () => {
    try {
      if (project.id && dataset.id) {
        await Apis.shared().project.deleteDataset(project.id, dataset.id);
        navigate("/" + PageInfo.datasetPath(project.id!, undefined));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteDatasetVersion = (versionId: ApiId) => {
    try {
      if (project.id && dataset.id) {
        Apis.shared().project.deleteDataset(project.id, versionId)
        .then(() => (Apis.shared().project.fetchDatasets(project.id!, dataset.id!)))
        .then(setDatasets)
        .catch(console.error);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    const monitorItems = datasets.items.filter((i) => ((i.job_id !== null) && checkIsPending(i.job?.status, i))).map((i) => i.job_id!);
    console.log("updating monitor items", monitorItems);
    itemMonitor(monitorItems);
  }, [datasets.items, itemMonitor]);

  useEffect(() => {
    console.log("fetching");
    Apis.shared().project.fetchDatasets(project.id!, dataset.id!)
      .then(setDatasets)
      .catch((error) => {
        console.error(error);
      })
    return () => (itemMonitor(null));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeVersion() {
    setReferenceVersionId(undefined);
  }

  return !dataset ? (
    <></>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
          <Nav to={"./.."}>{PageInfo.DatasetPage.name}</Nav>
          <Typography color="text.primary">{dataset.name}</Typography>
        </Breadcrumbs>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              <OriginalDatasetInfo dataset={dataset} labels={labelData} onDelete={onDelete} projectId={project.id!} onSelectReferenceId={setReferenceVersionId} sx={{ mt: 0 }} />
              {filteredVersions.length > 0 && (
                <Typography variant="h6" sx={{ mt: 2, ml: 2, mb: 1 }}>
                  Versions
                </Typography>
              )}
              <DatasetVersionsList
                versions={filteredVersions}
                original={dataset}
                processors={project.processors ?? []}
                projectId={project.id!}
                statusMap={statusMap}
                onDeleteDataset={onDeleteDatasetVersion}
                onSelectReferenceId={setReferenceVersionId}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DatasetVersionPanel source={dataset} versions={allVersions} referenceVersionId={referenceVersionId} onCreated={onCreated} onChangeVersion={onChangeVersion} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
