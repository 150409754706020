import { Button } from "@mui/material";

export type OperatorType = { id: string; text: string };

export const Operator: Record<string, OperatorType> = {
  ANY_OF: { id: "or", text: "any of" },
  ALL_OF: { id: "and", text: "all of" },
  NONE_OF: { id: "not", text: "none of" },
};

type OperationButtonProps = {
  op: OperatorType;
  onChange: (nextOperator: OperatorType) => void;
  operators?: OperatorType[];
};

export const OrderedOperators = [Operator.ANY_OF, Operator.ALL_OF, Operator.NONE_OF];

export function OperatorButton(props: OperationButtonProps) {
  const { op, onChange, operators = OrderedOperators } = props;
  function onClick() {
    const idx = (operators.findIndex((o) => o.id === op.id) + 1) % operators.length;
    onChange(operators[idx]);
  }

  return (
    <Button variant="text" onClick={onClick} sx={{ whiteSpace: "pre" }}>
      {op.text}
    </Button>
  );
}
