import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  styled,
  SxProps,
  Theme,
  Toolbar,
  Typography
} from "@mui/material";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from "react";
import { ApiId, DatasetModel, DataStatisticsResult, ExplorationFilter, LabelMappings } from "../../../api/apimodels";
import { Apis } from "../../../api/apis";
import { StableColorMap } from "../../../util/colormap";
import { LabelChart } from "./charts/LabelChart";
import { ThemeChart } from "./charts/ThemeChart";
import { TopicThemeChart } from "./charts/TopicThemeChart";
import { ColumnId } from '../../../api/data';
import { ConfigurableHeatmaps } from './charts/CustomHeatmap';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  sx?: SxProps<Theme>;
  projectId: ApiId;
  dataset: DatasetModel;
  filter?: ExplorationFilter;
  labels?: LabelMappings;
  themeColorFn: StableColorMap;
};

export default function AllChartsPanel(props: Props) {
  const { sx, projectId, dataset, filter, themeColorFn, labels } = props;
  const hasThemes = !!(dataset.columns ?? []).find((c) => (c === ColumnId.THEME));
  const [statistics, setStatistics] = useState<DataStatisticsResult>({
    themes: [],
    topics: [],
    filtered_rows: 0,
    total_rows: 0,
    original_texts: undefined,
  });
  useEffect(() => {
    const loadFn = async () => {
      const stats: DataStatisticsResult = await Apis.shared().data.queryStatistics(
        projectId,
        dataset.id!,
        dataset.filename!,
        filter,
        true,
        hasThemes,
        true
      );
      setStatistics(stats);
    };
    loadFn();
  }, [dataset.filename, dataset.id, filter, hasThemes, projectId]);
  return (
    <Stack direction="column" sx={sx}>
      {statistics.themes && statistics.themes.length > 0 && (
        <Box sx={{ height: "100%" }}>
          <Typography component="h3" variant="h5" sx={{ textAlign: "center", mt: 3 }}>
            Themes
          </Typography>
          <ThemeChart
            width={400}
            height={450}
            horizontal
            rows={statistics.themes}
            themeColorFn={themeColorFn}
            maxChipWidth="50em"
          />
        </Box>
      )}
      <Stack direction="column" gap={2} sx={{ width: "100%" }} justifyContent="flex-start" alignSelf="flex-start">
        {statistics.topics && statistics.topics.length > 0 && (
          <Stack sx={{ flexGrow: 1, pr: 2, height: "100%", mt: 4 }} direction="column" justifyContent="space-between">
            <Typography component="h3" variant="h5" sx={{ textAlign: "center", mb: 4, mt: 2 }}>
              Topics
            </Typography>
            <TopicThemeChart
              rows={statistics.topics}
              numRows={statistics.topics.length}
              themeTopic={statistics.theme_topics ?? []}
              themeColorFn={themeColorFn}
            />
            <Box />
          </Stack>
        )}
      </Stack>
      { labels && statistics && (statistics.filtered_rows > 0) && (
      <Stack direction="column" gap={2} sx={{ width: "100%" }} justifyContent="flex-start" alignSelf="flex-start">
        {dataset.id && (
          <Stack sx={{ flexGrow: 1, pr: 2, height: "100%", mt: 4 }} direction="column" justifyContent="space-between">
            <Typography component="h3" variant="h5" sx={{ textAlign: "center", mb: 4, mt: 2 }}>
              Heatmaps
            </Typography>
            <ConfigurableHeatmaps 
              labelMappings={labels} 
              themeColorFn={themeColorFn} 
              projectId={projectId} 
              datasetId={dataset.id!} 
              datasetFilename={dataset.filename!} 
              filter={filter} 
              hasThemes={statistics.themes && statistics.themes.length > 0}
              hasTopics={statistics.topics && statistics.topics.length > 0}
              />
            <Box />
          </Stack>
        )}
      </Stack>
      )}
      {statistics.labels && statistics.labels.length > 0 && (
        <Box>
          <Typography component="h3" variant="h5" sx={{ textAlign: "center", mb: 2, mt: 6 }}>
            Labels
          </Typography>
          <Grid container>
            {statistics.labels.map(([label, rows]) =>
                rows && (
                  <Grid key={label} item>
                    <LabelChart 
                      sx={{ mt: 4 }} 
                      labels={labels}
                      rows={rows} 
                      label={label}
                      labelColorFn={themeColorFn} />
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      )}
      {statistics.word && (
        <Box>
          <Typography component="h3" variant="h5" sx={{ textAlign: "center", mb: 2, mt: 6 }}>
            Original text statistics
          </Typography>
          <Box>
            <Typography>Total number of original texts: </Typography>
            <Typography>{statistics.word.original.total}</Typography>
            <Typography>Mean number of words per original text: </Typography>
            <Typography>{statistics.word.original.mean}</Typography>
            <Typography>Mean number of words per text</Typography>
            <Typography>{statistics.word.text.mean}</Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

const WideDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    minWidth: "600px",
    background: theme.palette.modalDark,
  },
}));

type AllChartsDialogProps = {
  open: boolean;
  projectId: ApiId;
  dataset: DatasetModel;
  labels?: LabelMappings;
  themeColorFn: StableColorMap;
  title: string;
  filter?: ExplorationFilter;
  onClose: () => void;
};

export function AllChartsDialog(props: AllChartsDialogProps) {
  const {open, projectId, dataset, labels, themeColorFn, title, filter, onClose } = props;
  return (
    <WideDialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AllChartsPanel projectId={projectId} dataset={dataset} themeColorFn={themeColorFn} filter={filter} labels={labels} />
      </DialogContent>
    </WideDialog>
  );
};
