import RemoveButtonIcon from "@mui/icons-material/HighlightOff";
import { Autocomplete, AutocompleteChangeReason, Box, FormControl, IconButton, Stack, SxProps, TextField, Theme } from "@mui/material";
import { emptyExplorationFilterClause, ExplorationFilterClause } from "../../../../api/apimodels";
import { SyntheticEvent, useState } from "react";
import { Operator, OperatorButton, OperatorType, OrderedOperators } from "./Operator";
import { FilterHeader, getBypassSx, MiniCheckbox, sectionLabelSx, TruncatedFilterClauseLabel } from "./common";
import { Tag } from "../../../../components/common/Tag";

interface FilterClauseOption {
  id: string,
  name: string
}

interface AutocompleteOption<T> {
  label: string,
  data: T
}

type FilterClauseProps<T> = {
  filter: ExplorationFilterClause | undefined;
  label?: string;
  standalone?: boolean;
  options: T[];
  addLabel: string;
  sx?: SxProps<Theme>;
  disableCapitalize?: boolean;
  onChange: (newFilter: ExplorationFilterClause) => void;
  onRemove?: () => void;
  validOperators?: OperatorType[];
};

export function FilterClause<T extends FilterClauseOption>(props: FilterClauseProps<T>) {
  const { filter, label, addLabel, onChange, options, standalone, onRemove, sx, disableCapitalize, validOperators = OrderedOperators } = props;
  const [rerenderTrigger, setRerenderTrigger] = useState(Date.now());
  const [op, setOp] = useState<OperatorType>(validOperators.find((o) => o.id === filter?.operator) ?? Operator.ANY_OF);
  const labelOptions:AutocompleteOption<T>[] = options.map((v) => ({ label: v.name, data: v}));

  function onOperatorChange(newOperator: OperatorType) {
    const newFilter: ExplorationFilterClause = filter ? { ...filter } : { ...emptyExplorationFilterClause };
    newFilter.operator = newOperator.id;
    setOp(newOperator);
    onChange(newFilter);
  }

  function onEnableChange() {
    const newFilter = { ...{ enabled: !!filter?.enabled, ids: [], operator: Operator.ANY_OF.id }, ...filter };
    newFilter.enabled = !newFilter.enabled;
    onChange(newFilter as ExplorationFilterClause);
  }

  function onAutocompleteChange(e: SyntheticEvent<Element>, value: AutocompleteOption<T> | null, reason: AutocompleteChangeReason) {
    // TODO: Ensure that value is on list
    if (value) {
      const newFilter: ExplorationFilterClause = filter ? { ...filter } : { ...emptyExplorationFilterClause };
      const newIds = new Set([...newFilter.ids]);
      newIds.add(value.data.id);
      newFilter.ids = Array.from(newIds);
      onChange(newFilter);
      setRerenderTrigger(rerenderTrigger + 1);
    }
  }

  function onRemoveItem(id: string) {
    const newFilter: ExplorationFilterClause = filter ? { ...filter } : { ...emptyExplorationFilterClause };
    const newIds = new Set([...newFilter.ids]);
    newIds.delete(id);
    newFilter.ids = Array.from(newIds);
    onChange(newFilter);
  }

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        options={labelOptions}
        sx={{ mt: 1, minWidth: "100%" }}
        onChange={onAutocompleteChange}
        renderInput={(params) => <TextField {...params} label={addLabel} variant="outlined" size="small" />}
      />
    );
  };

  const onRemoveClause = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRemove) {
      onRemove();
    }
  };

  const bypassSx = getBypassSx(!!filter?.enabled);
  const filterClauseSx:SxProps<Theme> = standalone ? {maxWidth: '7rem', fontSize: 'inherit'} : {};

  return (
    <Box sx={{ mt: 1, ...sx }}>
      <FormControl sx={{ width: "100%" }}>
        <Stack direction="column" alignItems="center">
          {label ? (
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
              {label && (
                <FilterHeader id="filter-panel-topic-label" sx={bypassSx}>
                  <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} />
                  <Stack direction="row" sx={standalone ? sectionLabelSx : {}} alignItems="center">
                  <TruncatedFilterClauseLabel variant="body1" sx={filterClauseSx}>{label}</TruncatedFilterClauseLabel>
                    {!standalone && (
                      <IconButton size="small" color="primary" onClick={onRemoveClause} sx={{ ml: 0.25 }}>
                        <RemoveButtonIcon fontSize="inherit" sx={{ position: "relative", top: -0.5 }} />
                      </IconButton>
                    )}
                  </Stack>
                </FilterHeader>
              )}
              <OperatorButton op={op} onChange={onOperatorChange} operators={validOperators} />
            </Stack>
          ) : (
            <OperatorButton op={op} onChange={onOperatorChange} operators={validOperators} />
          )}
          <Box alignSelf="flex-start" sx={{ mt: 0, ...bypassSx }}>
            {filter?.ids.map((id) => (
              <Tag
                key={`id_${id}`}
                label={labelOptions.find((o) => (o.data.id === id))?.data.name ?? id}
                title={id.replaceAll("-", " ")}
                onDelete={() => {
                  onRemoveItem(id);
                }}
                sx={{ maxWidth: standalone ? '13.5rem' : '12.5rem', m: 0.25, textTransform: !!disableCapitalize ? 'none' : "capitalize" }}
              />
            ))}
          </Box>
          <Box sx={{ width: "100%", mt: 0, mb: 1, ...bypassSx }}>
            {rerenderTrigger % 2 === 0 && renderAutocomplete()}
            {rerenderTrigger % 2 === 1 && renderAutocomplete()}
          </Box>
        </Stack>
      </FormControl>
    </Box>
  );
}
