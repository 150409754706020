import { ThemeOptions, ThemeProvider, colors, createTheme } from "@mui/material";
import { ReactNode } from "react";

export enum UITheme {
  dark = 'dark',
  light = 'light'
};

declare module '@mui/material/styles' {
  interface Palette {
    topic: Palette['primary'];
    subtopic: Palette['primary'];
    appBar: Palette['primary'];
    overlay: string;
    modalDark: string;
  }

  interface PaletteOptions {
    topic?: PaletteOptions['primary'];
    subtopic?: PaletteOptions['primary'];
    appBar?: PaletteOptions['primary'];
    overlay: string;
    modalDark: string;
  }
}

function getTheme(uiTheme: UITheme): ThemeOptions {
  const sharedOptions: ThemeOptions = {
    typography: {
      h6: {
        fontWeight: 400,
        letterSpacing: "0.015em",
        fontSmooth: "antialiased",
        textRendering: "optimizeLegibility",
      },
    },
  };
  if (uiTheme === UITheme.dark) {
    return {
      palette: {
        mode: "dark",
        subtopic: {
          main: colors.yellow[400],
          dark: colors.yellow[700],
          light: colors.yellow[200],
          contrastText: colors.yellow[100]
        },
        topic: {
          main: colors.grey[800],
          dark: colors.grey[900],
          light: colors.grey[700],
          contrastText: colors.common.white
        },
        appBar: {
          main: "#2d2d2d",
        },
        overlay: "rgba(32, 32, 32, 0.5)",
        modalDark: "rgb(24, 24, 24)"
      },
      ...sharedOptions,
    };
  }
  return {
    palette: {
      mode: "light",
      text: {
        primary: colors.common.black,
        secondary: colors.common.black,
      },
      topic: {
        main: colors.lightBlue[700],
        dark: colors.blueGrey[400],
        light: colors.blueGrey[300],
        contrastText: colors.common.white
    },
      subtopic: {
        main: colors.orange[400],
        dark: colors.orange[700],
        light: colors.orange[300],
        contrastText: colors.orange[100]
      },
      appBar: {
        main: "#1976d2"
      },
      overlay: "rgba(224, 224, 224, 0.5)",
      modalDark: "rgb(232, 232, 232)"
    },
    ...sharedOptions,
  };
}

export function ApplicationThemeProvider({children, uiTheme}: {uiTheme: UITheme, children: ReactNode}) {
  const defaultTheme = createTheme(getTheme(uiTheme));
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}