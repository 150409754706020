import { Box, Chip, Stack, styled, SxProps, Theme, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme } from "@mui/material";
import { FC } from "react";

export const CustomChipTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'capitalize',
  },
}));

export const Tag = styled(Chip)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 0.9,
  marginRight: theme.spacing(0.5),
  marginTop: theme.spacing(0.25),
  marginBottom: theme.spacing(0.25),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  backgroundColor: theme.palette.topic.main,
  color: theme.palette.topic.contrastText,
  textOverflow: "ellipsis",
  maxWidth: "17.5em"
}));

export const CustomChipBase = styled(Stack)(({ theme }) => ({
  fontSize: theme.typography.fontSize * 0.9,
  marginRight: theme.spacing(0.5),
  marginTop: theme.spacing(0.25),
  marginBottom: theme.spacing(0.25),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  height: theme.spacing(3.5),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800],
  minHeight: "1.0em",
}));

type ThemeChipProps = {
  sx?: SxProps<Theme>;
  label: string;
  markerColor?: string;
  noTooltip?: boolean;
  disableCapitalize?: boolean;
  disableSplitDashes?: boolean;
};

export const ThemeChip: FC<ThemeChipProps> = ({sx, label, markerColor, noTooltip, disableCapitalize, disableSplitDashes}) => {
  const theme = useTheme();
  const markerWidth = "16px";
  const renderLabel = !disableSplitDashes ? label.replaceAll("-", " ") : label;

   return (
     <CustomChipTooltip
       title={renderLabel}
       placement="right"
       disableFocusListener={!!noTooltip}
       disableHoverListener={!!noTooltip}
     >
       <Stack direction="row" sx={{py: .25, ...sx}} alignItems="center">
         <Box
           sx={{
             borderRadius: markerWidth,
             backgroundColor: markerColor,
             pl: theme.spacing(0.25),
             minWidth: markerWidth,
             minHeight: markerWidth,
             width: markerWidth,
             height: markerWidth,
           }}
         />
         <Typography variant="body2" component="div" sx={{width: '100%'}}>
           <Box
             sx={{
               ml: 1,
               mr: 1,
               whiteSpace: "nowrap",
               textTransform: !disableCapitalize ? "capitalize" : "",
               textOverflow: "ellipsis",
               overflow: "hidden",
             }}
           >
             {renderLabel}
           </Box>
         </Typography>
       </Stack>
     </CustomChipTooltip>
   );
}


export function CustomChip(props: ThemeChipProps) {
  const { label, sx, markerColor, noTooltip, disableCapitalize, disableSplitDashes } = props;
  const theme = useTheme();
  const markerWidth = "10px";
  const renderLabel = !disableSplitDashes ? label.replaceAll("-", " ") : label;
  return (
    <CustomChipTooltip title={renderLabel} placement="right" disableFocusListener={!!noTooltip} disableHoverListener={!!noTooltip}>
      <CustomChipBase sx={{...sx, 
          backgroundColor: theme.palette.topic.main, color: theme.palette.topic.contrastText}} direction="row" alignItems="center">
        {markerColor && (
          <Box
            sx={{
              borderRadius: markerWidth,
              backgroundColor: markerColor,
              pl: theme.spacing(0.25),
              minWidth: markerWidth,
              minHeight: markerWidth,
              width: markerWidth,
              height: markerWidth,
            }}
          />
        )}
        <Box sx={{ ml: 1, mr: 1, whiteSpace: "nowrap", textTransform: !disableCapitalize ? "capitalize" : "", textOverflow: "ellipsis", overflow: "hidden" }}>{renderLabel}</Box>
      </CustomChipBase>
    </CustomChipTooltip>
  );
}
