import { Box, FormControl, Stack, SxProps, Theme } from "@mui/material";
import { ExplorationSampleFilter } from "../../../../api/apimodels";
import { FilterClauseLabel, FilterHeader, getBypassSx, MiniCheckbox, sectionLabelSx } from "./common";
import { Operator } from "./Operator";

type FilterSelectedProps = {
  filter?: ExplorationSampleFilter;
  selectedIds?: string[];
  sx?: SxProps<Theme>;
  onChange: (sampleFilter: ExplorationSampleFilter) => void;
};

export function FilterSelected(props: FilterSelectedProps) {
  const { filter, onChange, sx, selectedIds = [] } = props;

  function onEnableChange() {
    const newFilter = { ...{ enabled: !!filter?.enabled, ids: [], operator: Operator.ALL_OF.id }, ...filter };
    newFilter.enabled = !newFilter.enabled;
    onChange(newFilter as ExplorationSampleFilter);
  }

  const bypassSx = getBypassSx(!!filter?.enabled);

  return (
    <Box sx={sx}>
      <FormControl sx={{ width: "100%" }}>
        <Stack direction="column" alignItems="center">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
            <FilterHeader id="filter-panel-selected-label" sx={{ ...bypassSx }}>
              <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} />
              <FilterClauseLabel variant="body1" sx={sectionLabelSx}>
                Selected rows {selectedIds.length > 0 ? ` ${selectedIds.length} ` : ""}
              </FilterClauseLabel>
            </FilterHeader>
          </Stack>
        </Stack>
      </FormControl>
    </Box>
  );
}
