export function arrayToDict<T>(a: T[], key: string): Record<string, T> {
  const r: Record<string, T> = {};
  a.forEach((i) => { r[(i as any)[key]] = i; });
  return r;
}

export function arrayToDictFn<T>(a: T[], keyFn: (x: any) => string): Record<string, T> {
  const r: Record<string, T> = {};
  a.forEach((i) => { r[keyFn(i)] = i; });
  return r;
}