import { ReactNode, createContext, useContext, useReducer } from "react";
import { Apis } from "../api/apis";
import { Session } from "../components/auth/session";
import { UITheme } from "../components/theme";

type ApplicationNotification = {
  id?: string; // Setting a constant ID can be used to group messages together that should all be cleared when closing one of them
  type: "info" | "warning" | "error";
  message: ReactNode;
  modal?: boolean;
}

export type ApplicationState = {
  maskTexts: boolean;
  uiTheme: UITheme;
  notifications: ApplicationNotification[];
};

export enum ApplicationActionTypes {
  login,
  logout,
  setProject,
  setLoading,
  addNotification,
  removeNotification,
  setMaskTexts,
  setTheme,
}

interface ApplicationAction {
  type: ApplicationActionTypes;
}

export interface LoginAction extends ApplicationAction {
  type: ApplicationActionTypes.login;
  token: string | undefined;
  refreshToken: string | undefined;
}

export interface LogoutAction extends ApplicationAction {
  type: ApplicationActionTypes.logout;
}

export interface AddNotification extends ApplicationAction {
  type: ApplicationActionTypes.addNotification;
  notification: ApplicationNotification;
}

export interface RemoveNotifiction extends ApplicationAction {
  type: ApplicationActionTypes.removeNotification;
  id: string;
}

export interface SetMaskTextsAction extends ApplicationAction {
  type: ApplicationActionTypes.setMaskTexts;
  isMasked: boolean;
}

export interface SetThemeAction extends ApplicationAction {
  type: ApplicationActionTypes.setTheme;
  theme: UITheme;
}

type AnyApplicationAction =
  | LoginAction
  | LogoutAction
  | AddNotification
  | RemoveNotifiction
  | SetThemeAction
  | SetMaskTextsAction;

const emptyApplicationState: ApplicationState = {
  notifications: [],
  uiTheme: (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? UITheme.dark : UITheme.light,
  maskTexts: false
};

function emptyStateFromStorage(): ApplicationState {
  let emptyState: ApplicationState = {...emptyApplicationState};
  return emptyState;
}

function uniqueId() {
  return Math.random() + '_' + Date.now();
}

function reducer(state: ApplicationState, action: AnyApplicationAction) {
  switch (action.type) {
    case ApplicationActionTypes.login:
      Session.shared().set(action.token!, action.refreshToken!);
      return {
        ...state
      };
    case ApplicationActionTypes.logout:
      Session.shared().clear();
      Apis.shared().setAuthentication(null, null);
      return {
        ...state
      };
    case ApplicationActionTypes.addNotification:
      return {
        ...state, notifications: [...state.notifications, {...action.notification, id: action.notification.id ?? uniqueId() }]
      };
    case ApplicationActionTypes.removeNotification:
      return {
        ...state, notifications: state.notifications.filter((n) => n.id !== action.id)
      };
    case ApplicationActionTypes.setMaskTexts:
      return {
        ...state, maskTexts: action.isMasked
      };
    case ApplicationActionTypes.setTheme:
      return {
        ...state, uiTheme: action.theme
      };
  }
  throw new Error("Unknown action.");
}

export const ApplicationContext = createContext(emptyStateFromStorage());
export const ApplicationDispatcherContext = createContext((_: AnyApplicationAction) => {});

export function useApplicationReducer() {
  return useReducer(reducer, emptyStateFromStorage());
}

export function useApplication() {
  return useContext(ApplicationContext);
}

export function useApplicationDispatch() {
  return useContext(ApplicationDispatcherContext);
}

export const ApplicationContextProvider = ({ children }: { children: ReactNode }) => {
  const [application, applicationDispatch] = useApplicationReducer();
  return (
    <ApplicationContext.Provider value={application}>
      <ApplicationDispatcherContext.Provider value={applicationDispatch}>{children}</ApplicationDispatcherContext.Provider>
    </ApplicationContext.Provider>
  );
};