import DatasetIcon from "@mui/icons-material/Dataset";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorIcon from "@mui/icons-material/Error";
import { Avatar, Box, IconButton, Link, ListItem, ListItemAvatar, ListItemText, styled, Typography, useTheme } from "@mui/material";
import { Apis } from "../../api/apis";
import { formatJsonDate } from "../../util/dateformat";
import { DateText } from "./DateText";

//
const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  "& .MuiListItemSecondaryAction-root": {
    right: 0
  }
}));

type Props = {
  name: string;
  description: string;
  createdAt?: string;
  downloadUrl?: string | undefined | null;
  filename: string | undefined | null;
  onDelete: () => void;
};

export default function DataHeaderItem(props: Props) {
  const { name, description, downloadUrl, filename, createdAt, onDelete } = props;
  const theme = useTheme();
  const isError = downloadUrl && !filename;

  const onDownload = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const link = document.createElement("a");
    link.download = filename!;
    let url = await Apis.shared().project.signedLink(downloadUrl ?? "");
    link.href = url;
    link.click();
  };

  return (
    <CustomListItem
      secondaryAction={
        <Link>
          {isError ? (
            <IconButton color="error" onClick={onDelete} size="small">
              <DeleteIcon />
            </IconButton>
          ) : (
            downloadUrl && (
              <IconButton color="primary" onClick={onDownload} size="small">
                <DownloadIcon />
              </IconButton>
            )
          )}
        </Link>
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ color: theme.palette.text.primary, background: isError ? theme.palette.grey[300] : undefined }}>
          {isError ? <ErrorIcon /> : <DatasetIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle1" fontWeight={600}>{name}</Typography>}
        secondary={
          <Box component="span">
            <Typography component="span" sx={{ display: "block" }}>
              {description}
              {!!createdAt && <DateText>Created: {formatJsonDate(createdAt)}, Filename: {filename}</DateText>}
            </Typography>
            {isError && (
              <Typography component="span" color="error" sx={{ display: "block" }}>
                Data is missing. Dataset is unusable.{" "}
              </Typography>
            )}
          </Box>
        }
      />
    </CustomListItem>
  );
}
