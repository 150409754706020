import { Box, Divider, Stack, SxProps, Theme } from "@mui/material";
import {
  ExplorationFilter,
  ExplorationFilterClause,
  ExplorationSampleFilter,
  ExplorationFilterClauses,
  LabelMappings,
} from "../../../api/apimodels";
import { StableColorMap } from "../../../util/colormap";
import { TopicFilter } from "./filter/TopicFilter";
import { FilterClause } from "./filter/FilterClause";
import { FilterSelected } from "./filter/FilterSelected";
import { Operator } from "./filter/Operator";
import { ReactNode } from "react";
import { LabelFilter } from "./filter/LabelFilter";
import { fakeNameFromId } from "./filter/common";

type Props = {
  filter?: ExplorationFilter;
  sx?: SxProps<Theme>;
  themeIds?: string[];
  topicIds?: string[];
  selectedIds: string[];
  labelMappings?: LabelMappings;
  themeColorFn: StableColorMap;
  onChange: (newFilter: ExplorationFilter, clearSelectedIds: boolean) => void;
};

function FilterPanel(props: Props) {
  const { sx, onChange, themeIds, topicIds, filter, selectedIds, labelMappings } = props;
  const showTopics = topicIds && topicIds.length > 0;
  const showTheme = themeIds && themeIds.length > 0;
  const showLabels = Object.keys(labelMappings ?? {}).length > 0;

  function onTopicFilterChange(topicFilter: ExplorationFilterClauses) {
    const newFilter = { ...filter };
    newFilter.topic = topicFilter;
    newFilter.sample = undefined;
    onChange(newFilter, true);
  }

  function onThemeFilterChange(themeFilter: ExplorationFilterClause) {
    const newFilter = { ...filter };
    newFilter.theme = themeFilter;
    newFilter.sample = undefined;
    onChange(newFilter, true);
  }

  function onLabelFilterChange(labelFilter: ExplorationFilterClauses) {
    const newFilter = { ...filter };
    newFilter.label = labelFilter;
    newFilter.sample = undefined;
    onChange(newFilter, true);
  }

  function onSelectedChange(sampleFilter: ExplorationSampleFilter) {
    const newFilter = { ...filter };
    newFilter.sample = sampleFilter;
    onChange(newFilter, false);
  }

  // Add the available filter sections to a list, render it with dividers below

  const sections: [string, ReactNode][] = [];
  if (showTopics) {
    sections.push(["topic", <TopicFilter filter={filter?.topic} onChange={onTopicFilterChange} topicIds={topicIds} />]);
  }
  if (showTheme) {
    sections.push([
      "theme",
      <FilterClause
        filter={filter?.theme}
        options={themeIds.map((id) => ({id, name: fakeNameFromId(id)}))}
        onChange={onThemeFilterChange}
        label="Theme Filter"
        addLabel="Add theme"
        standalone
        validOperators={[Operator.ANY_OF, Operator.NONE_OF]}
        sx={{mb: 2 }}
      />,
    ]);
  }
  if (showLabels) {
    sections.push(["labels", <LabelFilter filter={filter?.label} onChange={onLabelFilterChange} mappings={labelMappings ?? {}} sx={{mt: 1, mb: 3}} />]);
  }
  sections.push([
    "selected",
    <FilterSelected filter={filter?.sample} onChange={onSelectedChange} selectedIds={selectedIds} sx={{mt: 1}}/>,
  ]);

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="column">
        {sections.map(([key, section], i) => (
          <Box key={key}>
            {section}
            {(i < sections.length - 1) && sections.length > 1 && <Divider sx={{ mt: 1, mb: 1 }} />}
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
export default FilterPanel;
