import styled from "@emotion/styled";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button } from "@mui/material";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type Props = {
  label: string,
  onPick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  noIcon?: boolean;
  color?: "inherit" | "secondary" | "primary" | "success" | "error" | "info" | "warning";
  variant?: "text" | "outlined" | "contained";
}

export function FileUploadButton(props: Props) {
  const { onPick, label, disabled, color, variant, noIcon } = props;
  return (
    <Button
      component="label"
      className="upload-button"
      color={color ?? "secondary"}
      disabled={disabled}
      variant={variant ?? "outlined"}
      endIcon={noIcon ? undefined : <UploadFileIcon />}
    >
      {label}
      <VisuallyHiddenInput type="file" onChange={onPick} />
    </Button>
  );
}

export default FileUploadButton;