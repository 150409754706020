import { Box, Card, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";
import {
  DatasetModel,
  ProcessorModel,
  ProjectModel,
} from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { DatasetPage as DatasetPageInfo, processorPath } from "../../pages/PageInfo";
import { sortedResource } from "../../util/sorting";
import { LoaderButton } from "../common/LoaderButton";
import { CreateFormHeader } from "../common/ResourceCreateForm";
import ResourceSelect from "../common/ResourceSelect";
import ProcessorParameters, {configToVersionName, configToDescription} from "../processor/ProcessorParameters";
import LDAProcessorParameters from "../processor/LDAProcessorParameters";

function filterValidProcessors(processors: ProcessorModel[], requiredColumns: string[]): ProcessorModel[] {
  const rcSet = new Set(requiredColumns);
  return processors.filter((p) => {
    return !(p.manifest?.columns) ? true : (
      (new Set(p.manifest!.columns!.input)).difference(rcSet).size === 0
    );
  });
}

function asDict<T>(t: T[], keyFn: (item: T) => string): Record<string, T> {
  const result: Record<string, T> = {};
  t.forEach((t) => { result[keyFn(t)] = t; });
  return result;
}

function adaptConfigToProcessor(config: string, processor: ProcessorModel): string {
  const result: Record<string, any> = {};
  const conf: Record<string, any> = JSON.parse(config);
  const params = asDict(processor.manifest!.parameters, (p) => (p.name));
  Object.keys(params).forEach((k) => {
    result[k] = conf[k] ?? params[k].defaultValue;
  });
  return JSON.stringify(result);
}

function NewDatasetVersionForm(props: FormProps) {
  const { loading, versionData, setVersionData, source, versions } = props;
  const { name, description, processor, config, customName, customDescription } = versionData;
  const project = useRouteLoaderData("project") as ProjectModel;
  const processors = filterValidProcessors(sortedResource(project?.processors ?? [], true), source?.columns ?? []);
  
  const hasManifest = (!!processor?.manifest && (processor?.manifest as any).parameters);
  
  function getDefaultName(processor: ProcessorModel, config?: string) {
    return configToVersionName(config, processor.manifest);
  }

  function getDefaultDescription(processor: ProcessorModel, config?: string) {
    return configToDescription(config, processor.manifest);
  }
  const onProcessorParametersChanged =  (data: string) => {
    let n = customName || !processor ? name : getDefaultName(processor!, data);
    let d = customDescription ? description : getDefaultDescription(processor!, data);
    setVersionData({ ...versionData, config: data, name: n, description: d });
  };
  
  function onChangeProcessor(processor: ProcessorModel) {
    let n = customName ? name : getDefaultName(processor, config);
    let d = customDescription ? description : getDefaultDescription(processor, config);
    setVersionData({ ...versionData, processor, name: n, description: d, config: adaptConfigToProcessor(config ?? "{}", processor) });
  }

  function onChangeSourceDataset(newSource: DatasetModel) {
    setVersionData({ ...versionData, parentId: newSource.id! });
  }

  function onChangeName(name: string) {
    const customName = (name.length > 0);
    setVersionData({ ...versionData, name, customName });
  }

  function onChangeDescription(description: string) {
    const customDescription = (description.length > 0);
    setVersionData({ ...versionData, description, customDescription });
  }

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        "& .resource-create-form-header": { mt: 1, mb: 2, width: "100%" },
        "& .resource-select": { mb: 1, width: "100%" },
        "& .MuiTextField-root": { mb: 1, width: "100%" },
        "& .MuiDivider-root": { mt: 2, mb: 2, width: "100%" },
        "& .MuiButton-root": { mt: 1, alignSelf: "flex-end", mb: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      {project?.processors?.length && (
        <ResourceSelect
          className="resource-select"
          label="Source version"
          disabled={loading}
          selected={source as DatasetModel}
          resourceFilter={(r) => !!(r as DatasetModel).filename }
          onChange={(p) => onChangeSourceDataset(p)}
          resources={versions}
        />
      )}
      {project?.processors?.length && (
        <ResourceSelect
          className="resource-select"
          label="Model"
          disabled={loading}
          selected={processor as ProcessorModel}
          resourceFilter={(r) => !!(r as ProcessorModel).filename }
          onChange={(p) => onChangeProcessor(p)}
          resources={processors}
        />
      )}
      {processor && (
        hasManifest ? (
          <Box sx={{ mb: 2 }}>
            <ProcessorParameters
              manifest={processor.manifest}
              onChange={onProcessorParametersChanged}
              config={config}
              disabled={loading}
            />
          </Box>
        ) : (
          <Box sx={{ mb: 2 }}>
            <LDAProcessorParameters
              onChange={onProcessorParametersChanged}
              config={config}
              disabled={loading}
              cols={[2, 2, 3]}
            />
          </Box>
        )
      )}
      <TextField
        id="create-name"
        label="Version name"
        variant="filled"
        disabled={loading}
        value={name ?? ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChangeName(event.target.value);
        }}
      />
      <TextField
        id="create-description"
        label="Version description"
        multiline
        disabled={loading}
        rows={2}
        variant="filled"
        value={description ?? ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChangeDescription(event.target.value);
        }}
      />
    </Box>
  );
}

type FormProps = {
  loading: boolean;
  versionData: FormData;
  versions: DatasetModel[];
  source?: DatasetModel;
  setVersionData: (data: FormData) => void;
};

type FormData = {
  parentId: number;
  customName?: boolean;
  customDescription?: boolean;
  processor?: ProcessorModel | undefined;
  name?: string;
  description?: string;
  config?: string;
};

type DatasetVersionPanelProps = {
  source: DatasetModel, 
  versions: DatasetModel[], 
  title?: string, 
  referenceVersionId?: number,
  onCreated?: () => void,
  onChangeVersion: () => void,
};

const DatasetVersionPanel = (props: DatasetVersionPanelProps) => {
  const {versions, referenceVersionId, title, onCreated, onChangeVersion } = props;
  const [loading, setLoading] = useState(false);
  const project = useRouteLoaderData("project") as ProjectModel;
  const [versionData, setVersionData] = useState<FormData>({parentId: props.source.id!});
  const source = versions.find((v) => v.id === versionData.parentId) ?? props.source!;
  const refData = versions.find((v) => (v.id === referenceVersionId));
  
  const apis = Apis.shared();
  const navigate = useNavigate();
  const location = useLocation();
  
  async function onSubmit() {
    const { name, description, processor, config } = versionData;
    setLoading(true);
    try {
      await apis.project.createProcessedDataset(project.id!, source.id!, name, description, processor?.id!, JSON.parse(config ?? "{}"));
      (onCreated && onCreated());
    } catch (e) {
      console.error("Start job failed!", e);
    }
    setLoading(false);
    navigate(location, { replace: true });
  }

  function canSubmit(formData: FormData): boolean {
    return (
      !!formData.name &&
      !!formData.processor
    );
  }

  useEffect(() => {
    if (referenceVersionId !== undefined) {
      onChangeVersion();
      if (refData && refData.job_id) {
        Apis.shared().project.fetchJob(project.id!, refData.job_id)
          .then((job) => {
            setVersionData({
              parentId: refData.id!,
              name: refData.name,
              processor: (project.processors!).find((p) => (p.id === job.processor_id)),
              description: refData.description,
              config: job.parameters,
            });
          })
          .catch((error) => {
            setVersionData({
              parentId: refData.id!,
              name: refData.name,
              description: refData.description,
            });
          })
      } else if (refData) {
        setVersionData({
          parentId: refData.id!,
          name: refData.name,
          description: refData.description,
        });
      }
    }
  }, [onChangeVersion, project.id, project.processors, refData, referenceVersionId]);

  const hasProcessors = (project.processors?.length ?? 0) > 0;

  return (
    <Card sx={{ p: 2 }}>
      <CreateFormHeader title={title ?? "New Version"} icon={DatasetPageInfo.menuIcon} sx={{mb: 2}}/>
      {hasProcessors ? (
        <NewDatasetVersionForm
          loading={loading}
          source={source}
          versions={versions}
          versionData={versionData}
          setVersionData={setVersionData}
        />
      ) : (
        <Box>
          <Typography>
            No models are avaialble. Please upload a model first at the <Link to={"/" + processorPath(project.id!)} component={RouterLink} color="inherit">Model page</Link>
          </Typography>
        </Box>
      )}
      <LoaderButton
        title="Create"
        loading={loading}
        variant="outlined"
        color="secondary"
        disabled={!hasProcessors && !canSubmit(versionData)}
        onClick={onSubmit}
        sx={{mb: 0}}
      />
    </Card>
  );
}

export default DatasetVersionPanel;
