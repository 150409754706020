import CheckBoxCheckedIcon from "@mui/icons-material/CheckBox";
import CheckBoxBlankIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { Box, styled, SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import { SyntheticEvent } from "react";

export function getBypassSx(enabled: boolean): SxProps<Theme> {
  return { opacity: enabled ? 1.0 : 0.25 };
}

export const sectionLabelSx: SxProps<Theme> = {
  fontSize: "105%",
  fontWeight: 300,
};

export const FilterClauseLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  color: theme.palette.text.primary,
}));

export const TruncatedFilterClauseLabel = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  maxWidth: '5rem',
  textOverflow: "ellipsis", 
  overflow: "hidden",
  whiteSpace: 'pre',
  fontSize: theme.typography.fontSize,
  color: theme.palette.text.primary,
}));

export const NestedFilterSection = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
  py: 0,
  borderRadius: 1
}));

export const FilterHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
}));

type MiniCheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  sx?: SxProps<Theme>;
};

export function MiniCheckbox(props: MiniCheckboxProps) {
  const { checked, onChange, sx } = props;
  const onClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(!checked);
  };
  return (
    <Box onClick={onClick} sx={{ lineHeight: "100%", ...sx }}>
      {checked ? (
        <CheckBoxCheckedIcon fontSize={"inherit"} sx={{ mr: 1 }} />
      ) : (
        <CheckBoxBlankIcon fontSize={"inherit"} sx={{ mr: 1 }} />
      )}
    </Box>
  );
}

export function fakeNameFromId(id: string, capitalize: boolean = true): string {
  return capitalize 
    ? id.split('-').map((w) => (w.charAt(0).toUpperCase() + w.slice(1))).join(' ')
    : id.split('-').join(' ');
}
