import { emptyExplorationFilterClause, LabelMapping, LabelMappings } from "../../../../api/apimodels";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  FormControl,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import {
  emptyExplorationFilterClauses,
  ExplorationFilterClause,
  ExplorationFilterClauses,
} from "../../../../api/apimodels";
import { useState } from "react";
import { Operator, OperatorButton, OperatorType, OrderedOperators } from "./Operator";
import { FilterClauseLabel, FilterHeader, getBypassSx, MiniCheckbox, NestedFilterSection, sectionLabelSx } from "./common";
import { FilterClause } from "./FilterClause";

type LabelFilterProps = {
  filter: ExplorationFilterClauses | undefined;
  mappings: LabelMappings;
  sx?: SxProps<Theme>;
  onChange: (newFilter: ExplorationFilterClauses) => void;
};

export function LabelFilter(props: LabelFilterProps) {
  const { filter, onChange, sx, mappings } = props;
  const labels = Object.values(mappings).toSorted((x) => x.order);
  const [rerenderTrigger, setRerenderTrigger] = useState(Date.now());
  const [op, setOp] = useState<OperatorType>(
    OrderedOperators.find((o) => o.id === filter?.operator) ?? Operator.ANY_OF
  );

  const onEnableChange = () => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    newFilter.enabled = !newFilter.enabled;
    onChange(newFilter as ExplorationFilterClauses);
  };

  const onChangeClause = (index: number, clause: ExplorationFilterClause) => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    const newClauses = [...newFilter.clauses];
    newClauses[index] = clause;
    newFilter.clauses = newClauses;
    onChange(newFilter);
  };

  const onOperatorChange = (newOperator: OperatorType) => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    newFilter.operator = newOperator.id;
    setOp(newOperator);
    onChange(newFilter);
  };

  const onRemoveClause = (index: number) => {
    const newFilter: ExplorationFilterClauses = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
    const newClauses = [...newFilter.clauses];
    newClauses.splice(index, 1);
    newFilter.clauses = newClauses;
    onChange(newFilter);
  };

  const onAutocompleteChange = (
    event: React.SyntheticEvent,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    if (value) {
      const label = Object.values(mappings).find((v) => v.name === value) as LabelMapping;
      const newFilter = filter ? { ...filter } : { ...emptyExplorationFilterClauses };
      const clause: ExplorationFilterClause = { ...emptyExplorationFilterClause };
      clause.id = label.id;
      newFilter.clauses = (newFilter.clauses ?? []).concat([clause]);
      onChange(newFilter);
      setRerenderTrigger(rerenderTrigger + 1);
    }
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        disabled={!filter?.enabled}
        options={labels.map((l) => l.name)}
        sx={{ mt: 1, minWidth: "100%" }}
        onChange={onAutocompleteChange}
        renderInput={(params) => <TextField {...params} label={"Add label"} variant="outlined" size="small" />}
      />
    );
  };
  const bypassSx = getBypassSx(!!filter?.enabled);

  return (
    <Box sx={sx}>
      <FormControl sx={{ width: "100%" }}>
        <Stack direction="column" alignItems="center">
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%", mb: 1 }}>
            <FilterHeader id="filter-panel-theme-label">
              <MiniCheckbox checked={!!filter?.enabled} onChange={onEnableChange} sx={{ fontSize: "18px" }} />
              <FilterClauseLabel variant="body1" sx={{ ...sectionLabelSx, ...bypassSx } as SxProps<Theme>}>
                Label Filter
              </FilterClauseLabel>
            </FilterHeader>
            <OperatorButton op={op} onChange={onOperatorChange} />
          </Stack>
          {(filter?.clauses ?? []).map((clause, i) => {
            return (
              <NestedFilterSection key={`_${i}`} sx={{ px: 1, mb: 1, ...bypassSx }}>
                <FilterClause
                  filter={clause}
                  label={mappings[clause.id!].name}
                  addLabel={"Add value"}
                  disableCapitalize={true}
                  options={mappings[clause.id!].values!.toSorted((a, b) => a.order - b.order).map((v) => ({id: v.value, name: v.name}))}
                  onChange={(clause: ExplorationFilterClause) => onChangeClause(i, clause)}
                  validOperators={[Operator.ANY_OF, Operator.NONE_OF]}
                  onRemove={() => {
                    onRemoveClause(i);
                  }}
                />
              </NestedFilterSection>
            );
          })}
          <Box sx={{ minWidth: "100%" }}>
            {rerenderTrigger % 2 === 0 && renderAutocomplete()}
            {rerenderTrigger % 2 === 1 && renderAutocomplete()}
          </Box>
        </Stack>
      </FormControl>
    </Box>
  );
}
