export type StableColorMap = (id: string) => string;

export function buildFromIds(ids: string[], palette: string[], defaultColor: string): StableColorMap {
  const map: Record<string, string> = {};
  ids.forEach((id, i) => {
    map[id] = palette[i % palette.length];
  });
  return (id: string) => {
    return map[id] ?? defaultColor;
  };
}